<template>
	<div>
		<vuestic-widget>
			<div class="convainer mt-4">
				<div class="row">
					<div class="col">
						<div class="form-group">
							<multiselect
								:loading="loadingEmpresa"
								placeholder="Empresa"
								label="nome"
								selectLabel="Selecione"
								:options="empresas"
								v-model="empresaSelecionada"
								:key="'id'"
								id="search-empresa"
								selectedLabel="Selecionado"
								deselectLabel="Pressione para remover"
								track-by="id"
								:preserve-search="true"
							>
								<span slot="noResult">Empresa não encontrada</span>
							</multiselect>
							<label class="select-control-label" for="search-empresa">
								Pesquisar por empresa</label
							>
						</div>
					</div>
				</div>
				<data-tables-server
        v-show="empresaSelecionada"
					v-loading="loading"
					:pagination-props="{ pageSizes: [10, 20, 50, 100] }"
					style="width: 100%;"
					:data="checklists"
					:table-props="{ stripe: true }"
					@row-dblclick="rowClick"
				>
					<el-table-column
						label="Checklist"
						sortable="custom"
						prop="nome"
					></el-table-column>
				</data-tables-server>
				<div class="mt-4 row d-flex justify-content-end">
					<button
						class="btn btn-pale btn-micro mr-2"
						@click="push('lista-checklist')"
					>
						Voltar
					</button>
				</div>
			</div>
		</vuestic-widget>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

import notificationMixin from '@/utils/notification';

export default {
  mixins: [notificationMixin],
  data() {
    return {
      loading: false,
      empresaSelecionada: null,
      empresas: [],
      checklists: [],
      loadingEmpresa: false,
    };
  },
  created() {
    this.getEmpresa();
  },
  methods: {
    ...mapActions('empresa', ['getEmpresasList']),
    ...mapActions('checklist', ['getChecklistFull']),
    push(name, params) {
      this.$router.push({
        name,
        params,
      });
    },
    async getEmpresa() {
      try {
        this.loadingEmpresa = true;
        this.empresas = await this.getEmpresasList();
      } catch (error) {
        this.message('error', 'Erro na operação');
      } finally {
        this.loadingEmpresa = false;
      }
    },
    async getChecklist() {
      try {
        this.loading = true;
        const { id } = this.empresaSelecionada;
        this.checklists = await await this.getChecklistFull({
          params: { empresa: id, 'sem-resposta': '' },
        });
      } catch (error) {
        console.error(error);
        this.message('error', 'Erro na operação');
      } finally {
        this.loading = false;
      }
    },

    rowClick(val) {
      this.push('lista-checklist-duplicate-user-supervisor', { checklist: val.id });
    },
  },
  watch: {
    empresaSelecionada(val) {
      if (val !== null) {
        this.getChecklist();
      } else {
        this.checklists = [];
      }
    },
  },
};
</script>

<style></style>
